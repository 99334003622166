import * as React from 'react';
import { lazy, Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './i18n';
import App from './App';

const ChatHead = lazy(() => import('./components/ChatHead/ChatHead'));

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const rootElement = document.getElementById('root');

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<Suspense fallback={<div></div>}>
			<Route exact path="/:dealerId" component={App} />
			<Route exact path="/:dealerId/button" component={ChatHead} />
		</Suspense>
	</BrowserRouter>,
	rootElement
);
