import * as React from 'react';
import { Component, lazy, Suspense } from 'react';
import { RouteComponentProps } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { fetchCredentials } from './api/fetchCredentials';
import { fetchWebChatConfiguration } from './api/fetchWebChatConfiguration';
import { fetchPreloadUserByData } from './api/fetchPreloadUserByData';
import { fetchActiveMessageMatch } from './api/fetchWelcomeMessage';
import { fetchIpAddress } from './api/fetchIpAddress';
import { fetchDealer } from './api/fetchDealer';
import { postHubSpotContactNote } from './api/postHubSpotContactNote';

import * as postTrackWebchatViews from './api/postTrackWebchatViews';
import { postTrackUser } from './api/postTrackUser';
import './App.css';
import AppProps from './AppProps';
import AppState from './AppState';
import { postTypingNotificationMessage } from './api/postTypingNotificationMessage';
import { ChatWindow } from './components/ChatWindow/ChatWindow';
import { ChatWelcome } from './components/ChatWelcome/ChatWelcome';
import UserProfile from './models/UserProfile';
import AuthData from './models/AuthData';
import { UserProfileType } from './models/UserProfileType';
import { WebChatThemeMode } from './models/WebChatThemeMode';
import LanguageText from './models/LanguageText';
import WebChatConfigurationEx from './models/WebChatConfigurationEx';
import ActiveMessageMatch from './models/ActiveMessageMatch';
import i18next from 'i18next';
import WebchatLocale from './models/Locale';
import themes from './theme.json';
import { initializeGATag, gtagConfig, gtagEvent } from './ga/gtag';

const AuthWindow = lazy(() => import('./components/AuthWindow/AuthWindow'));
const AuthWindowSMSSmall = lazy(() => import('./components/AuthWindowSMSSmall/AuthWindowSMSSmall'));
const AuthWindowDeptSmall = lazy(() => import('./components/AuthWindowDeptSmall/AuthWindowDeptSmall'));
const SmsDialog = lazy(() => import('./components/SmsDialog/SmsDialog'));
const BuyWindow = lazy(() => import('./components/BuyWindow/BuyWindow'));

initializeGATag('G-L13Z70FYY8', '', '');

export default class App extends Component<RouteComponentProps<AppProps>, AppState> {
	private dealerId: string;
	private authError: boolean;
	private changeText: LanguageText;
	private lastTimeTyping: number | null = null;
	private lastTimeTypingThreshold: number = 1000;
	private clickedActiveMessage: boolean = false;
	private authenticatedTimeout: any;
	private scrollToBottomTimeout: any;
	private waitingForBotReplyTimeout: any;
	//private inactiveTimeout: any;

	constructor(props: any) {
		super(props);
		this.dealerId = this.props.match.params.dealerId;
		this.changeText = {
			continue: 'CONTINUE WITH:',
			login: 'OR CHAT WITHOUT LOG IN',
			sales: 'CHAT ABOUT SALES',
			parts: 'CHAT ABOUT PARTS',
			services: 'CHAT ABOUT SERVICES',
			loginWithInfo: 'BEGIN CHAT'
		};

		var currentTime = new Date();
		var currentTimeHour = currentTime.getHours();
		var currentHour = currentTimeHour.toString();
		var currentTimeMinute = currentTime.getMinutes();
		var currentMinute = currentTimeMinute.toString();

		if (currentTimeHour < 10)
		{
			currentHour = "0" + currentTimeHour.toString();
		}

		if (currentTimeMinute < 10)
		{
			currentMinute = "0" + currentTimeMinute.toString();
		}

		this.state = {
			mobile: false,
			activeMessageMatch: null,
			smsDialogOpen: false,
			hasEngaged: false,
			expandMode: false,
			userTyped: false,
			themeName: null,
			themeNameSelected: false,
			dispatchCalled: false,
			authData: null,
			webchatConnecting: false,
			preloadUserChange: false,
			shouldHideChat: false,
			hasPreloadQuery: false,
			chatOpened: false,
			webChatLoaded: false,
			getButton: '',
			getHelp: false,
			userMessageSent: false,
			selectedVINForPurchase: '',
			paymentContactInfo: {
				firstName: '',
				lastName: '',
				email: '',
				phoneNumber: ''
			},
			paymentRes: null,
			manualResetWebchatDialogOpen: false,
			siteUrl: '',
			ipAddress: '',
			userAgent: '',
			smsSet: false,
			waitingForBotReply: false,
			cid: '',
			currentTime: currentHour + ':' + currentMinute,
			store: null,
			metadata: null,
		};

		this.authError = false;
		this.postWelcomePopupClosedMessage = this.postWelcomePopupClosedMessage.bind(this);

		gtagConfig('G-L13Z70FYY8', 'dimension1', this.dealerId);
	}

	//#region Hooks
	async componentDidMount() {
		if (window.parent != null) {
			window.addEventListener('message', this.onReceiveMessageFromParent.bind(this));
		}

		// Check for updates to user's preferred color scheme, using https://stackoverflow.com/a/59621903
		const lightModePreference = window.matchMedia('(prefers-color-scheme: light)');
		const darkModePreference = window.matchMedia('(prefers-color-scheme: dark)');
		lightModePreference.addEventListener('change', (e) => e.matches && this.activateLightMode());
		darkModePreference.addEventListener('change', (e) => e.matches && this.activateDarkMode());

		this.getSettings(this.dealerId);
	}

	componentWillUnmount() {
		clearInterval(this.authenticatedTimeout);
	}
	//#endregion

	activateLightMode() {
		if (this.state.config === null || this.state.config === undefined) {
			this.onChangeTheme('light', false);
		} else if (
			!(this.state.config === null || this.state.config === undefined) &&
			this.state.config.themeMode === WebChatThemeMode.Auto &&
			!this.state.themeNameSelected
		) {
			this.onChangeTheme('light', false);
		}
	}

	activateDarkMode() {
		if (this.state.config === null || this.state.config === undefined) {
			this.onChangeTheme('dark', false);
		} else if (
			!(this.state.config === null || this.state.config === undefined) &&
			this.state.config.themeMode === WebChatThemeMode.Auto &&
			!this.state.themeNameSelected
		) {
			this.onChangeTheme('dark', false);
		}
	}

	raiseEvent(event, payload) {
		window.parent.postMessage({ sender: 'ChatWindow', event: 'apievent', payload: { event, data: payload} }, '*');
	}

	renderAuthWindow(config, authWindowStyle) {
		if (authWindowStyle) {
			switch (authWindowStyle) {
				case 3:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindowSMSSmall
								text={this.changeText}
								themeName={this.state.themeName}
								onChangeTheme={(themeName) => this.onChangeTheme(themeName)}
								configuration={config}
								onAuthenticated={this.onAuthWindowAuthenticated.bind(this)}
								onClose={() => this.persistState({ opened: false })}
								smsSet={this.state.smsSet}
							/>
						</Suspense>
					);
				case 4:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindowDeptSmall
								text={this.changeText}
								themeName={this.state.themeName}
								onChangeTheme={(themeName) => this.onChangeTheme(themeName)}
								configuration={config}
								onAuthenticated={this.onAuthWindowAuthenticated.bind(this)}
								onClose={() => this.persistState({ opened: false })}
								smsSet={this.state.smsSet}
							/>
						</Suspense>
					);
				case 0:
				default:
					return (
						<Suspense fallback={<div></div>}>
							<AuthWindow
								text={this.changeText}
								configuration={config}
								onAuthenticated={this.onAuthWindowAuthenticated.bind(this)}
								onClose={() => this.persistState({ opened: false })}
							/>
						</Suspense>
					);
			}
		}
		return (
			<Suspense fallback={<div></div>}>
				<AuthWindow
					text={this.changeText}
					configuration={config}
					onAuthenticated={this.onAuthWindowAuthenticated.bind(this)}
					onClose={() => this.persistState({ opened: false })}
				/>
			</Suspense>
		);
	}

	renderBuyWindowLazy() {
		if (this.state.buyPopupOpened && this.state.selectedVINForPurchase) {
			return (
				<Suspense fallback={<div></div>}>
					<BuyWindow
						themeName={this.state.themeName}
						dealerId={this.dealerId}
						conversationId={this.state.token.conversationId}
						vin={this.state.selectedVINForPurchase}
						paymentRes={this.state.paymentRes}
						contactInfo={this.state.paymentContactInfo}
						setContactInfo={(val) => this.persistState({ paymentContactInfo: val })}
						onClose={() =>
							this.persistState({ buyPopupOpened: false, paymentRes: null, selectedVINForPurchase: null })
						}
						setPaymentSuccess={this.setPaymentSuccess.bind(this)}
					/>
				</Suspense>
			);
		}

		return <></>;
	}

	renderSMSDialog(config) {
		if (this.state.smsDialogOpen) {
			return (
				<Suspense fallback={<div></div>}>
					<SmsDialog
						themeName={this.state.themeName}
						open={this.state.smsDialogOpen}
						onClose={() => {
							this.setState({
								smsDialogOpen: false
							});
							this.postSMSOpened(false);
						}}
						dealerId={this.state.config.dealerId}
						color={config.accentColor}
						onSendSMS={this.sendDealerGAEvent.bind(this)}
					/>
				</Suspense>
			);
		}
		return <></>;
	}

	private resetWebChat() {
		var currentTime = new Date();
		var currentTimeHour = currentTime.getHours();
		var currentHour = currentTimeHour.toString();
		var currentTimeMinute = currentTime.getMinutes();
		var currentMinute = currentTimeMinute.toString();

		if (currentTimeHour < 10)
		{
			currentHour = "0" + currentTimeHour.toString();
		}

		if (currentTimeMinute < 10)
		{
			currentMinute = "0" + currentTimeMinute.toString();
		}

		this.setState(
			{
				smsDialogOpen: false,
				hasEngaged: false,
				userTyped: false,
				dispatchCalled: false,
				chatOpened: false,
				webChatLoaded: false,
				token: undefined,
				manualResetWebchatDialogOpen: false,
				currentTime: currentHour + ':' + currentMinute,
				store: null,
			},
			() => {
				this.postHasEngaged(false);
				this.postUserTyped(false);
				this.postDispatchCalled(false);
				this.postMessage('Token', null);
				this.persistState({
					hasEngaged: false,
					userTyped: false,
					dispatchCalled: false,
					token: null
				});

				clearInterval(this.authenticatedTimeout);

				this.fetchDirectlineToken();
			}
		);
	}

	private fetchDirectlineToken() {
		if (this.state.config.mode === 1) {
			// need to validate name and email and preload user info is invalid
			if (this.state.config.isPrivate) {
				// authenticate only when has valid user info
				if (!this.state.shouldHideChat) {
					// when reset, mode = 1 && isPrivate = true && user info from query is valid, then reset
					// this user info to newly created chat again
					this.onAuthenticated(this.state.userProfile);
				}
			} else {
				// work as original
				this.onAuthenticated(null);
			}
		} else {
			// if not mode one, then keep it as unauthenticated
			this.postAuthenticatedMessage(false);
		}
    }

	private handleChatOpened(chatOpened) {
		this.setState({ chatOpened: chatOpened });
	}

	private handleWebChatLoaded() {
		this.setState({ webChatLoaded: true });
	}

	private handleUpdateGetHelp(getHelp) {
		if (!this.state.chatOpened) {
			this.handleChatOpened(true);
		}
		this.setState({ getHelp: getHelp });
	}

	private handleUpdateGetButton(getButton) {
		if (!this.state.chatOpened) {
			this.handleChatOpened(true);
		}
		this.setState({ getButton: getButton });
	}

	private setStore(store: any) {
		this.setState({ store: store });
	}

	private onChangeLocale(language) {
		i18next.changeLanguage(language.botFrameworkLocale).then(() => {
			this.persistState({ language: language });
		});
	}

	private onChangeTheme(themeName, themeSelected = true) {
		this.persistState({ themeName: themeName });
		this.setThemeState({ themeName: themeName });
		if (themeSelected) {
			this.onChangeThemeSelected(true);
		}
	}

	private onChangeThemeSelected(themeNameSelected) {
		this.persistState({ themeNameSelected: themeNameSelected });
	}

	private onDispatchCalled(dispatchCalled) {
		this.persistState({ dispatchCalled: dispatchCalled });
	}

	private directLineConnect(dispatchCalled, preloadUserChange) {
		this.persistState({
			dispatchCalled: dispatchCalled,
			preloadUserChange: preloadUserChange
		});
	}

	private onUserMessage(hasEngaged) {
		this.persistState({ hasEngaged: hasEngaged });
	}

	private setVINForPurchase(vin) {
		this.persistState({
			selectedVINForPurchase: vin
		});
	}

	private setPaymentSuccess(vin, paymentId) {
		this.persistState({
			paymentRes: {
				vin,
				paymentId
			}
		});
	}

	private handleManualResetWebchatDialogOpen(manualResetWebchatDialogOpen) {
		this.setState({
			manualResetWebchatDialogOpen: manualResetWebchatDialogOpen
		});
	}

	render() {
		const { opened, welcomeOpened, buyPopupOpened, userId, token, config, mobile, expandMode, authWindowStyle } = this.state;

		const isActiveMessage = this.state.activeMessageMatch !== null;
		const isAuthenticated =
			this.state.token !== undefined &&
			this.state.token !== null &&
			this.state.token.conversationId !== undefined;

		if (!config) {
			return null;
		}

		const classes = ['window-container'];

		if (mobile) {
			classes.push('mobile');
		}
		if (expandMode) {
			classes.push('expandMode');
		}

		// window container class name
		let windowContainerClassName = classes.join(' ');
		if (
			this.state.config.mode === 1 &&
			((this.state.config.isPrivate && this.state.shouldHideChat) || !isAuthenticated)
		) {
			windowContainerClassName = '';
		}

		let currentTheme =
			this.state.themeName !== null && this.state.themeName !== undefined ? this.state.themeName : 'light';

		let menuIconColor = themes[currentTheme]['webchatHeaderIconColor'];

		const containerStyles = {
			'--primary-color': config.accentColor,
			'--primary-color-50': config.accentColor + '80',
			'--primary-color-04': config.accentColor + '0A',
			'--primary-color-4f': config.accentColor + '4F',
			'--text-color': config.textColor === undefined || config.textColor === '' ? '#ffffff' : config.textColor
		} as React.CSSProperties;

		return (
			<div className={`app theme-${this.state.themeName}`} style={containerStyles}>
				<CSSTransition
					in={welcomeOpened}
					classNames={'css-transition-' + (mobile ? (config.alignRight ? 'left' : 'right') : 'up')}
					timeout={this.state.expandMode ? 0 : 400}
					exit
					mountOnEnter
					unmountOnExit={true}
				>
					<ChatWelcome
						message={
							isActiveMessage && this.state.activeMessageMatch.message !== ''
								? this.state.activeMessageMatch.message
								: this.state.config.welcomeMessageSecondary
						}
						mobile={mobile}
						configuration={config}
						onClick={() => this.onClickChatWelcomeMessage()}
						onClose={() => {
							this.persistState({ welcomeOpened: false });
							this.postWelcomePopupClosedMessage();
						}}
					/>
				</CSSTransition>
				<CSSTransition
					in={opened}
					classNames={'css-transition-up'}
					timeout={this.state.expandMode ? 0 : 400}
					mountOnEnter
					onExited={this.onPopupAnimationCompleted.bind(this)}
				>
					<TransitionGroup className={windowContainerClassName}>
						{this.state.config.mode === 1 ? (
							(!this.state.config.isPrivate || !this.state.shouldHideChat) && isAuthenticated ? (
								<CSSTransition classNames={'css-transition-left'} timeout={this.state.expandMode ? 0 : 400} mountOnEnter>
									<div className="chat-windows-container">
										<ChatWindow
											configuration={config}
											userId={userId}
											token={token}
											language={this.state.language}
											onClose={() => {
												this.persistState({
													opened: false
												});
											}}
											onBuyPopupOpen={() =>
												this.persistState({
													buyPopupOpened: true
												})
											}
											onLanguageChange={(language) => {
												this.onChangeLocale(language);
											}}
											onTokenRefreshed={this.onTokenRefreshed.bind(this)}
											dispatchCalled={this.state.dispatchCalled}
											onDispatchActivity={this.onDispatchActivity.bind(this)}
											showSmsButton={!this.state.hasEngaged}
											onClickSmsButton={() => {
												this.setState({
													smsDialogOpen: true
												});
												this.postSMSOpened(true);
											}}
											smsSet={this.state.smsSet}
											themeName={this.state.themeName}
											onChangeTheme={(themeName) => this.onChangeTheme(themeName)}
											color={menuIconColor}
											mobile={mobile}
											hasEngaged={this.state.hasEngaged}
											expandMode={this.state.expandMode}
											shouldHideChat={this.state.shouldHideChat}
											userProfile={this.state.userProfile}
											onConnectionStatusFailed={this.onConnectionStatusFailed}
											onAuthenticated={this.onAuthenticated.bind(this)}
											postAuthenticatedMessage={this.postAuthenticatedMessage.bind(this)}
											postWelcomeHeightMessage={this.postWelcomeHeightMessage.bind(this)}
											resetWebChat={this.resetWebChat.bind(this)}
											fetchDirectlineToken={this.fetchDirectlineToken.bind(this)}
											webchatConnecting={this.state.webchatConnecting}
											chatOpened={this.state.chatOpened}
											handleChatOpened={this.handleChatOpened.bind(this)}
											webChatLoaded={this.state.webChatLoaded}
											handleWebChatLoaded={this.handleWebChatLoaded.bind(this)}
											getHelp={this.state.getHelp}
											onHelp={this.handleUpdateGetHelp.bind(this)}
											getButton={this.state.getButton}
											onButton={this.handleUpdateGetButton.bind(this)}
											waitingForBotReply={this.state.waitingForBotReply}
											userMessageSent={this.state.userMessageSent}
											handleUserMessageSent={(userMessageSent) =>
												this.setState({
													userMessageSent: userMessageSent
												})
											}
											activeMessageMatch={this.state.activeMessageMatch}
											setVINForPurchase={this.setVINForPurchase.bind(this)}
											setPaymentSuccess={this.setPaymentSuccess.bind(this)}
											manualResetWebchatDialogOpen={this.state.manualResetWebchatDialogOpen}
											handleManualResetWebchatDialogOpen={this.handleManualResetWebchatDialogOpen.bind(
												this
											)}
											currentTime={this.state.currentTime}
											store={this.state.store}
											setStore={this.setStore.bind(this)}
											setMetadata={(metadata) =>
												this.setState({
													metadata: metadata
												})
											}
										/>
										{ this.renderSMSDialog(config)}
									</div>
								</CSSTransition>
							) : (
								<></>
							)
						) : !isAuthenticated || this.authError ? (
							<CSSTransition
								key="0"
								classNames={'css-transition-left'}
								timeout={400}
								mountOnEnter
								unmountOnExit
							>
								{this.renderAuthWindow(config, authWindowStyle)}
							</CSSTransition>
						) : (
							<CSSTransition key="1" classNames={'css-transition-left'} timeout={this.state.expandMode ? 0 : 400} mountOnEnter>
								{
									<ChatWindow
										configuration={config}
										userId={userId}
										token={token}
										language={this.state.language}
										onClose={() => this.persistState({ opened: false })}
										onBuyPopupOpen={() =>
											this.persistState({
												buyPopupOpened: true
											})
										}
										onLanguageChange={(language) => {
											this.onChangeLocale(language);
										}}
										onTokenRefreshed={this.onTokenRefreshed.bind(this)}
										dispatchCalled={this.state.dispatchCalled}
										onDispatchActivity={this.onDispatchActivity.bind(this)}
										showSmsButton={false}
										onClickSmsButton={() => {
											this.setState({
												smsDialogOpen: true
											});
											this.postSMSOpened(true);
										}}
										smsSet={this.state.smsSet}
										themeName={this.state.themeName}
										onChangeTheme={(themeName) => this.onChangeTheme(themeName)}
										color={menuIconColor}
										mobile={mobile}
										hasEngaged={this.state.hasEngaged}
										expandMode={this.state.expandMode}
										shouldHideChat={this.state.shouldHideChat}
										userProfile={this.state.userProfile}
										onConnectionStatusFailed={this.onConnectionStatusFailed}
										onAuthenticated={this.onAuthenticated.bind(this)}
										postAuthenticatedMessage={this.postAuthenticatedMessage.bind(this)}
										postWelcomeHeightMessage={this.postWelcomeHeightMessage.bind(this)}
										resetWebChat={this.resetWebChat.bind(this)}
										fetchDirectlineToken={this.fetchDirectlineToken.bind(this)}
										webchatConnecting={this.state.webchatConnecting}
										chatOpened={this.state.chatOpened}
										handleChatOpened={this.handleChatOpened.bind(this)}
										webChatLoaded={this.state.webChatLoaded}
										handleWebChatLoaded={this.handleWebChatLoaded.bind(this)}
										getHelp={this.state.getHelp}
										onHelp={this.handleUpdateGetHelp.bind(this)}
										getButton={this.state.getButton}
										onButton={this.handleUpdateGetButton.bind(this)}
										waitingForBotReply={this.state.waitingForBotReply}
										userMessageSent={this.state.userMessageSent}
										handleUserMessageSent={(userMessageSent) =>
											this.setState({
												userMessageSent: userMessageSent
											})
										}
										activeMessageMatch={this.state.activeMessageMatch}
										setVINForPurchase={this.setVINForPurchase.bind(this)}
										setPaymentSuccess={this.setPaymentSuccess.bind(this)}
										manualResetWebchatDialogOpen={this.state.manualResetWebchatDialogOpen}
										handleManualResetWebchatDialogOpen={this.handleManualResetWebchatDialogOpen.bind(
											this
										)}
										currentTime={this.state.currentTime}
										store={this.state.store}
										setStore={this.setStore.bind(this)}
										setMetadata={(metadata) =>
											this.setState({
												metadata: metadata
											})
										}
									/>
								}
							</CSSTransition>
						)}
					</TransitionGroup>
				</CSSTransition>
				<CSSTransition
					in={opened && buyPopupOpened}
					classNames={'css-transition-left'}
					timeout={400}
					mountOnEnter
				>
					{this.renderBuyWindowLazy()}
				</CSSTransition>
			</div>
		);
	}

	private onClickChatWelcomeMessage() {
		this.clickedActiveMessage = true;
		this.persistState({ opened: true, welcomeOpened: false });
		this.postWelcomePopupClosedMessage();
		this.postPopupMessage(true);
	}

	private persistState<K extends keyof AppState>(state: Pick<AppState, K>, callback?: () => void) {
		super.setState(state, () => {
			let settings: any = {};

			if (this.state.opened !== undefined) {
				settings.opened = this.state.opened;
			}
			if (this.state.welcomeOpened !== undefined) {
				settings.welcomeOpened = this.state.welcomeOpened;
			}
			if (this.state.config !== undefined) {
				settings.config = this.state.config;
			}
			if (this.state.userId !== undefined) {
				settings.userId = this.state.userId;
			}
			if (this.state.userProfile !== undefined) {
				settings.userProfile = this.state.userProfile;
			}
			if (this.state.token !== undefined) {
				settings.token = this.state.token;
			}
			if (this.state.authWindowStyle !== undefined) {
				settings.authWindowStyle = this.state.authWindowStyle;
			}
			if (this.state.language !== undefined) {
				settings.language = this.state.language;
			}
			if (this.state.themeName !== undefined) {
				settings.themeName = this.state.themeName;
			}
			if (this.state.themeNameSelected !== undefined) {
				settings.themeNameSelected = this.state.themeNameSelected;
			}
			if (this.state.hasEngaged !== undefined) {
				settings.hasEngaged = this.state.hasEngaged;
			}
			if (this.state.userTyped !== undefined) {
				settings.userTyped = this.state.userTyped;
			}
			if (this.state.dispatchCalled !== undefined) {
				settings.dispatchCalled = this.state.dispatchCalled;
			}
			if (this.state.authData !== undefined) {
				settings.authData = this.state.authData;
			}

			this.setSettings(this.dealerId, settings);

			if (callback) {
				callback();
			}
		});
	}

	private setThemeState<K extends keyof AppState>(state: Pick<AppState, K>, callback?: () => void) {
		super.setState(state, () => {
			let themeSetting: any = {};

			if (this.state.themeName !== undefined) {
				themeSetting.themeName = this.state.themeName;
			}

			this.setThemeSetting(this.dealerId, themeSetting);

			if (callback) {
				callback();
			}
		});
	}

	private setThemeSetting(dealerId: string, themeSetting: any) {
		this.postMessage('SetThemeSetting', {
			dealerId: dealerId,
			themeSetting: themeSetting
		});
	}

	private setSettings(dealerId: string, settings: any) {
		this.postMessage('SetSettings', {
			dealerId: dealerId,
			settings: settings
		});
	}

	private getSettings(dealerId: string) {
		this.postMessage('GetThemeSetting', { dealerId: dealerId });
		this.postMessage('GetSettings', { dealerId: dealerId });
	}

	private postMessage(event: string, payload: any) {
		window.parent.postMessage({ sender: 'ChatWindow', event: event, payload: payload }, '*');
	}

	private postPopupMessage(open: boolean) {
		this.postMessage('Popup', open);

		// GA - closing popup
		gtagEvent('Interaction', open ? 'Open Popup from Welcome' : 'Close Popup', 'G-L13Z70FYY8');
	}

	private postHasEngaged(hasEngaged) {
		this.postMessage('HasEngaged', hasEngaged);
	}

	private postUserTyped(userTyped) {
		this.postMessage('UserTyped', userTyped);
	}

	private postSMSOpened(smsOpened) {
		this.postMessage('SMSOpened', smsOpened);
	}

	private postDispatchCalled(dispatchCalled) {
		this.postMessage('DispatchCalled', dispatchCalled);
	}

	private postWelcomePopupClosedMessage() {
		this.postMessage('WelcomePopup', false);
	}

	private postInitialization(
		config: WebChatConfigurationEx,
		shouldHideChat: boolean,
		hasPreloadQuery: boolean,
		authenticated: boolean,
		opened: boolean,
		welcomeOpened: boolean,
		authWindowStyle: number,
		hasActiveMessage: boolean,
		hasEngaged: boolean,
		userTyped: boolean,
		dispatchCalled: boolean
	) {
		this.postMessage('Initialization', {
			config: config,
			shouldHideChat: shouldHideChat,
			hasPreloadQuery: hasPreloadQuery,
			authenticated: authenticated,
			opened: opened,
			welcomeOpened: welcomeOpened,
			authWindowStyle: authWindowStyle,
			hasActiveMessage: hasActiveMessage,
			hasEngaged: hasEngaged,
			userTyped: userTyped,
			dispatchCalled: dispatchCalled
		});
	}

	private postAuthenticatedMessage(authenticated: boolean) {
		this.postMessage('Authenticated', authenticated);
	}

	private postWelcomeHeightMessage(height: any) {
		this.postMessage('WelcomeHeight', height);
	}

	private async onReceivedSettingsFromParent(payload: any) {
		let {
			config,
			token,
			authWindowStyle,
			language,
			themeName,
			themeNameSelected,
			dispatchCalled,
			userProfile,
			preloadUserChange,
			shouldHideChat
		} = this.state;

		// get authenticated status
		let authenticated = token !== undefined && token !== null && token.conversationId !== undefined;

		// if config.mode === 1, then authenticate based on condition
		if (config.mode === 1) {
			// 1. user first reach this page, then authenticate, create a bot user and chat
			// 2. previous preload user NOT match current preload user when config.isPrivate is true, then user change,
			// then authenticate, create new bot user and new chat
			// preloadUserChange can only be true when config.isPrivate is true
			if (!authenticated || preloadUserChange) {
				// need to validate name and email and preload user info is invalid
				if (config.isPrivate) {
					// authenticate only when has valid user info
					if (!shouldHideChat) {
						this.onAuthenticated(userProfile);
					}
				} else {
					// work as original
					this.onAuthenticated(null);
				}
			}
		}

		// pre-set locale
		if (!config.supportedLocales) {
			// supported locale list is empty, then always be english
			language = new WebchatLocale();
			language.code = 'en';
			language.botFrameworkLocale = 'en-US';
		} else if (language === undefined || !config.supportedLocales.some((t) => t.code === language.code)) {
			// no state language is set or no supported locales match the state language
			let fullBrowserLanguage = window.navigator.language;
			let partialBrowserLanguage = window.navigator.language.split('-')[0]; // en, es

			let matchedLocale = null;

			for (let locale of config.supportedLocales) {
				if (fullBrowserLanguage === locale.code) {
					matchedLocale = locale;
					break;
				} else if (partialBrowserLanguage === locale.code) {
					matchedLocale = locale;
				}
			}

			if (matchedLocale === null) {
				language = config.supportedLocales[0]; // en-US, es
			} else {
				language = matchedLocale;
			}
		}

		// pre-set theme
		if (themeName === undefined || themeName === null) {
			// if no themeName (set in cookies)
			let themePicked = 'light'; // set default theme to light

			// reset theme based on webchat configuration
			if (config.themeMode === WebChatThemeMode.Auto) {
				// config.themeMode default is auto
				if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
					themePicked = 'dark';
				} // else use light
			} else if (config.themeMode === WebChatThemeMode.Dark) {
				themePicked = 'dark';
			} // else then set to light

			// assign theme
			themeName = themePicked;
			// if there was no themeName set in cookies, themeNameSelected is false
			themeNameSelected = false;
		} // if has themeName (from cookies), then take that one as priority

		// pre-set dispatch call
		if (dispatchCalled === undefined || dispatchCalled === null) {
			dispatchCalled = false;
		}

		// track webchat views -- every info is ready, then track page view
		let isUniqueVisitor = Object.keys(payload).length === 0 ? true : false;
		await postTrackWebchatViews.postTrackViews(config.dealerId, `${authWindowStyle}`, isUniqueVisitor);

		// GA Setup
		gtagConfig('G-L13Z70FYY8', 'dimension2', '' + config.mode + '-' + authWindowStyle);

		// change language and persist state
		i18next.changeLanguage(language.botFrameworkLocale).then(async () => {
			this.persistState(
				{
					config: config,
					authWindowStyle: authWindowStyle,
					language: language,
					themeName: themeName,
					themeNameSelected: themeNameSelected,
					dispatchCalled: dispatchCalled
				},
				() => {
					this.postMessage('ParentUrl', null);
				}
			);
			this.setThemeState({ themeName: themeName });
		});

		let dealer = await fetchDealer(this.dealerId);
		this.persistState({ smsSet: dealer.sms !== undefined && dealer.sms !== null && dealer.sms !== ''});

		this.persistState({ buttonsList: [] });
	}

	private async onReceiveMessageFromParent(e: MessageEvent) {
		const { event, payload } = e.data;

		if (event === 'GetThemeSetting') {
			// console.debug('retrieved theme setting in chat window', payload);
			this.setState({
				themeName:
					payload.themeName !== null && payload.themeName !== undefined
						? payload.themeName
						: this.state.themeName
			});
		} else if (event === 'GetSettings') {
			// console.debug('retrieved settings in chat window', payload);
			this.setState({siteUrl: payload.currentUrl.replace(/\/$/, '')});
			var ipAddress = await fetchIpAddress();
			this.setState({ipAddress: ipAddress});
			this.setState({userAgent: navigator.userAgent});

			// settings from cookies
			let settingsPayload = payload.settings;
			let { config, token, authWindowStyle, userProfile } = settingsPayload; // from cookie if have
			let { preloadUserChange, shouldHideChat, hasPreloadQuery } = this.state; // from state

			// move below part from onReceivedSettingsFromParent to here
			// can set info to state earlier before directline connection
			// e.g. for this : preloadUserChange
			// get config is not set in cookies
			if (config === undefined) {
				config = await fetchWebChatConfiguration(this.dealerId);
			}

			if (config.isPrivate && payload.overridePrivate) {
				config.isPrivate = false;
			}

			// define authWindowStyle
			if (authWindowStyle === undefined) {
				if (config.authWindowStyles != null && config.authWindowStyles.length > 0) {
					authWindowStyle = config.authWindowStyles[0];
				} else {
					authWindowStyle = 0;
				}
			}

			// get preload user
			// set this: preloadUserChange, earlier
			// if mode is 1
			if (config.mode === 1) {
				// define auth window
				authWindowStyle = 0;

				// if this flag is true, then we check name, email, phone
				if (config.isPrivate) {
					// get preloadUser by data
					let preloadUser = await fetchPreloadUserByData(this.dealerId, payload.name, payload.email, payload.phone);
					// define has query flag
					hasPreloadQuery = preloadUser.hasPreloadQuery;

					// update userProfile - phone number is not always given
					if (preloadUser.preloadUserEmail && preloadUser.preloadUserName) {
						userProfile = new UserProfile();
						userProfile.email = preloadUser.preloadUserEmail;
						userProfile.name = preloadUser.preloadUserName;
						if (preloadUser.preloadUserPhone) {
							userProfile.phone = preloadUser.preloadUserPhone;
						}
						userProfile.type = UserProfileType.Preload;

						// record valid query
						userProfile.preloadQuery = preloadUser.preloadUserQuery;

						// do not hide chat window
						shouldHideChat = false;

						// both state and current preload users have value (both valid preload users), but not match
						// compare with one from state (which is from cookie)
						// preload user change, not the previous user
						if (settingsPayload.userProfile) {
							if (
								settingsPayload.userProfile.email !== userProfile.email ||
								settingsPayload.userProfile.name !== userProfile.name ||
								settingsPayload.userProfile.phone !== userProfile.phone ||
								settingsPayload.userProfile.preloadQuery !== userProfile.preloadQuery
							) {
								preloadUserChange = true;

								// if preload user change (for config.mode === 1 only), set token to be undefined, need to re-authenticate
								token = undefined;
								this.postAuthenticatedMessage(false);
							}
						}
					} else {
						// hide chat window
						shouldHideChat = true;
					}

					// if hubspot id is set, set it in state (only if isPrivate is true)
					if (payload.cid) {
						this.setState({cid: payload.cid});
					}
				} // otherwise it works as original
			}

			// set state
			this.setState(
				{
					authWindowStyle: authWindowStyle,
					config: config,
					hasEngaged: settingsPayload.hasEngaged ? settingsPayload.hasEngaged : false,
					userTyped: settingsPayload.userTyped ? settingsPayload.userTyped : false,
					language: settingsPayload.language,
					opened: this.state.mobile ? false : settingsPayload.opened,
					themeName:
						this.state.themeName === 'light' || this.state.themeName === 'dark'
							? this.state.themeName
							: settingsPayload.themeName,
					themeNameSelected: this.state.themeNameSelected || settingsPayload.themeNameSelected ? true : false,
					token: token,
					userId: settingsPayload.userId,
					welcomeOpened: settingsPayload.welcomeOpened,
					dispatchCalled: settingsPayload.dispatchCalled,
					authData: settingsPayload.authData,
					userProfile: userProfile,
					preloadUserChange: preloadUserChange,
					shouldHideChat: shouldHideChat,
					hasPreloadQuery: hasPreloadQuery
				},
				() => {
					this.onReceivedSettingsFromParent(payload);
				}
			);
		} else if (event === 'Popup') {
			// console.debug('Received popup message: ' + payload);
			if (payload) {
				const isActiveMessage = this.state.activeMessageMatch !== null;

				if (isActiveMessage && !this.clickedActiveMessage && this.state.token === undefined) {
					this.onClickChatWelcomeMessage();
				}

				this.persistState({ opened: true });
			} else {
				this.persistState({ opened: false });
			}
		} else if (event === 'WelcomePopup') {
			if (payload) {
				this.persistState({ welcomeOpened: true });
			} else {
				this.persistState({ welcomeOpened: false });
			}
		} else if (event === 'HasEngaged') {
			this.onUserMessage(payload);
		} else if (event === 'ExpandMode') {
			this.setState({ expandMode: payload});
		} else if (event === 'DispatchCalled') {
			this.onDispatchCalled(payload);
		} else if (event === 'Mobile') {
			this.setState({ mobile: payload });
			if (payload) {
				this.setState({ opened: false });
			}
		} else if (event === 'ParentUrl') {
			const url = payload;
			try {
				let activeMessageMatch: ActiveMessageMatch | null = null;
				try {
					if (this.state.config.mode === 1 && this.state.authWindowStyle === 0) {
						activeMessageMatch = await fetchActiveMessageMatch(
							this.state.config.dealerId,
							url,
							this.state.language.botFrameworkLocale.split('-')[0]
						);
					}
				} catch (e) {}
				this.setState({ activeMessageMatch: activeMessageMatch });

				this.postInitialization(
					this.state.config,
					this.state.shouldHideChat,
					this.state.hasPreloadQuery,
					this.state.token !== undefined &&
						this.state.token !== null &&
						this.state.token.conversationId !== undefined,
					this.state.opened,
					this.state.welcomeOpened,
					this.state.authWindowStyle,
					activeMessageMatch != null,
					this.state.hasEngaged,
					this.state.userTyped,
					this.state.dispatchCalled
				);
			} catch (e) {
				console.error(e);
			}
		} else if (event === 'api.openchat') {
			window.parent.postMessage({ sender: 'ChatWindow', event: 'Popup', payload: true }, '*');
		} else if (event === 'api.closechat') {
			this.persistState({
				chatOpened: false,
				opened: false
			});
		}
	}

	private async onAuthWindowAuthenticated(userProfile: UserProfile | null, authData?: AuthData) {
		this.onAuthenticated(userProfile, authData);
		this.setState({ hasEngaged: true });
		this.postHasEngaged(true);
	}

	private async onAuthenticated(userProfile: UserProfile | null, authData?: AuthData) {
		// authentication will always treat as new user
		console.debug('onAuthenticated', userProfile);
		let credentials = await fetchCredentials(this.dealerId, /*this.state.userId ? this.state.userId :*/ null);
		this.authError = false;

		// set userId, userProfile and token in state
		this.persistState({
			userId: credentials.userId,
			userProfile: userProfile,
			token: credentials.directLineToken,
			authData: authData
		});

		this.postAuthenticatedMessage(true);

		if (userProfile) {
			let stringType = '';
			if (userProfile.type === UserProfileType.Preload) {
				stringType = 'Preload';
			} else if (userProfile.type === UserProfileType.FacebookWebchat) {
				stringType = 'Facebook';
			} else if (userProfile.type === UserProfileType.Apple) {
				stringType = 'Apple';
			} else {
				stringType = 'Google';
			}

			gtagConfig('G-L13Z70FYY8', 'user_id', credentials.userId);
			gtagEvent('User', 'Auth - ' + stringType, 'G-L13Z70FYY8');
		} else {
			gtagConfig('G-L13Z70FYY8', 'user_id', credentials.userId);
			gtagEvent('User', 'Auth - Anonymous', 'G-L13Z70FYY8');
		}

		postTrackUser(
			this.dealerId,
			this.state.userId,
			this.state.token.conversationId,
			this.getParentUrl(),
			'auth',
			this.state.config.mode + '-' + (this.state.authWindowStyle ?? 0)
		);
	}

	private onDispatchActivity({ dispatch }: any) {
		return (next: any) => (action: any) => {
			if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
				this.setState({ webchatConnecting: true });
				if (!this.state.dispatchCalled || this.state.preloadUserChange) {
					// This should only be called once at the start of the conversation
					dispatch({
						type: 'WEB_CHAT/SEND_EVENT',
						payload: {
							name: 'webchat/join',
							value: {
								userProfile: this.state.userProfile,
								activeMessageMatch: this.state.activeMessageMatch,
								browserLanguage: window.navigator.language,
								webchatLanguage: this.state.language.code,
								sendSupportedFeatures: this.state.config.mode === 1 ? 'false' : 'true',
								leadAction: this.state.authData ? this.state.authData.leadAction : null,
								browserTime: this.state.currentTime,
								metadata: this.state.metadata,
							}
						}
					});
					this.setState({ waitingForBotReply: true });

					if (this.state.metadata) {
						this.setState({ hasEngaged: true });
						this.postHasEngaged(true);
					}

					// First message
					this.sendDealerGAEvent('Interaction', 'dealerai_webchat_engaged');
					this.requestGAClientID();

					// If isPrivate is true and data-cid is set, send a note to hubspot when user first engages with webchat
					if (this.state.cid !== '') {
						postHubSpotContactNote(this.state.cid);
					}
				}
			} else if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {

				this.requestGAClientID();
				action.payload.activity.channelData = {
					dealerId: this.dealerId,
					browserLanguage: window.navigator.language,
					webchatLanguage: this.state.language.code,
					url: this.state.siteUrl,
					ipAddress: this.state.ipAddress,
					userAgent: this.state.userAgent
				};
				this.setState({ waitingForBotReply: true });
				this.waitingForBotReplyTimeout = setTimeout(() => {
					console.debug("Clear wait bot reply timeout");
					this.setState({ waitingForBotReply: false });
				}, 120000)
			} else if (action.type === 'WEB_CHAT/SEND_MESSAGE') {
				// Clear timeouts
				//clearTimeout(this.inactiveTimeout);

				let lastTimeTyping = Date.now();
				if (
					this.lastTimeTyping == null ||
					lastTimeTyping - this.lastTimeTyping > this.lastTimeTypingThreshold
				) {
					this.lastTimeTyping = lastTimeTyping;
					postTypingNotificationMessage(
						this.state.config.portalBaseUrl,
						this.state.config.dealerId,
						this.state.token.conversationId,
						this.lastTimeTyping
					);
				}
				if (!this.state.hasEngaged) {
					postTrackUser(
						this.dealerId,
						this.state.userId,
						this.state.token.conversationId,
						this.getParentUrl(),
						'engaged',
						this.state.config.mode + '-' + (this.state.authWindowStyle ?? 0)
					);

					// track webchat views -- track conversation, first user message
					postTrackWebchatViews.postTrackConversations(this.dealerId, `${this.state.authWindowStyle ?? 0}`);
				}
				this.setState({ hasEngaged: true });
				this.postHasEngaged(true);
				this.setState({ userMessageSent: true });
				this.setState({ userTyped: true });
				this.postUserTyped(true);

				this.raiseEvent('messageSent', action.payload);
			} else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
				if (action.payload.activity.type === "typing") {
					// Prevent sending the typing activity in order to show the bot avatar
					return null;
				}

				// conversation is in take over mode
				if (action.payload.activity.type === "handoff") {
					this.setState({ waitingForBotReply: false });
					return null;
				}

				// GA event
				if (action.payload.activity.type === "dealerai_webchat_lead") {
					this.sendDealerGAEvent('Interaction', 'dealerai_webchat_lead');
					return null;
				}

				if (
					(!action.payload.activity.name || action.payload.activity.name !== 'inactive') &&
					(!action.payload.activity.text || action.payload.activity.text !== 'Hey, are you still there?')) {
					// Message sent by the bot
					/*clearTimeout(this.inactiveTimeout);
					this.inactiveTimeout = setTimeout(() => {
						console.log("Sending inactive event");
						// Notify bot the user has been inactive
						dispatch({
							type: 'WEB_CHAT/SEND_EVENT',
							payload: {
								name: 'inactive',
								value: {
									userProfile: this.state.userProfile,
									activeMessageMatch: this.clickedActiveMessage ? this.state.activeMessageMatch : null,
									browserLanguage: window.navigator.language,
									webchatLanguage: this.state.language.code,
								}
							}
						});
					}, 15000)*/
					if (!this.state.dispatchCalled) {
						this.directLineConnect(true, false);
						// this.onDispatchCalled(true);
						this.postDispatchCalled(true);
					}
					this.setState({ webchatConnecting: false });
				}

				if (action.payload.activity.type === 'message'
					&& action.payload.activity.from && action.payload.activity.from.role === 'bot' 
					&& action.payload.activity.value !== 'DEBUGMESSAGE' && action.payload.activity.value !== 'WAITPROGRESS') {

					this.setState({ waitingForBotReply: false });
					if (this.waitingForBotReplyTimeout) {
						clearTimeout(this.waitingForBotReplyTimeout);
					}
				}

				if (new Date(action.payload.activity.timestamp) > new Date()) {
					if (document.querySelector('ul[role="list"]').lastElementChild) {
						if (this.scrollToBottomTimeout) {
							clearTimeout(this.scrollToBottomTimeout);
                        }
						this.scrollToBottomTimeout = setTimeout(() => {
							console.debug('scroll to bottom');
							document.querySelector('ul[role="list"]').lastElementChild.scrollIntoView();
						}, 150);
					}
				}
				this.raiseEvent('messageReceived', action.payload);
			}
			return next(action);
		};
	}

	private getParentUrl(): string {
		var isInIframe = window.parent !== window,
			parentUrl = null;
		if (isInIframe) {
			parentUrl = document.referrer;
		}
		return parentUrl;
	}

	private onPopupAnimationCompleted(e: HTMLElement) {
		this.postPopupMessage(false);
	}

	private onTokenRefreshed(newToken: string) {
		const expiry = (JSON.parse(atob(newToken.split('.')[1]))).exp * 1000;
		console.debug('token refresh - expiry', new Date(expiry));
		this.persistState({ token: { ...this.state.token, token: newToken, expiryDate: new Date(expiry)} });
	}
	
	private onConnectionStatusFailed() {
		this.persistState({ token: undefined });
	}

	private sendDealerGAEvent(category: string, action: string) {
		console.debug('ga event', category, action);
		if (this.state.config.customTracking !== undefined && this.state.config.customTracking.isEnabled !== undefined && this.state.config.customTracking.isEnabled === true && this.state.config.customTracking.gA4Id !== undefined && this.state.config.customTracking.gA4Id !== '') {
			
			window.parent.postMessage({ sender: 'ChatWindow', event: 'gaevent', payload: { category, action, trackingId: this.state.config.customTracking.gA4Id } }, '*');
		}
	}

	private requestGAClientID() {
		if (this.state.config.customTracking !== undefined && this.state.config.customTracking.isEnabled !== undefined && this.state.config.customTracking.isEnabled === true && this.state.config.customTracking.gA4Id !== undefined && this.state.config.customTracking.gA4Id !== '') {

			window.parent.postMessage({ sender: 'ChatWindow', event: 'gaclientid', payload: { trackingId: this.state.config.customTracking.gA4Id } }, '*');
		}
	}
}
