import React, { Suspense } from 'react';
import Button from '../Button/Button';
import CloseIcon from '../IconButtons/CloseIcon';
import Brightness4OutlinedIcon from '../IconButtons/Brightness4OutlinedIcon';
import Brightness7OutlinedIcon from '../IconButtons/Brightness7OutlinedIcon';
import WebChatWelcome from '../WebChatWelcome/WebChatWelcome';
import ChatWindowProps from './ChatWindowProps';
import ChatWindowState from './ChatWindowState';
import i18next from 'i18next';
import './ChatWindow.css';

import Color from './Color';
import Solver from './Solver';

const WebChatReal = React.lazy(() => import('../WebChatReal/WebChatReal'));
const SMSIconSvg = React.lazy(() => import('../IconButtons/SMSIconSvg'));
const ExitChatIcon = React.lazy(() => import('../IconButtons/ExitChatIcon'));

function classList(classes) {
  return Object.entries(classes)
    .filter((entry) => entry[1])
    .map((entry) => entry[0])
    .join(' ');
}

let prevMessageFrom: string | null = null;

const activityMiddleware =
  () =>
    (next) =>
      (card) =>
        (...children) => {
          const { activity, nextVisibleActivity } = card;

          if (activity.type !== 'message') {
            return null;
          }

          const messageFrom = activity.from.role;

          var styleOptions = {};
          // Checks for takeover text
          if (activity && activity.from && activity.from.role === 'auto') {
            styleOptions = {
              fontSize: '80%',
              fontStyle: 'italic'
            };
          }

          const isTakeoverMessage = !(Object.keys(styleOptions).length === 0);

          let nextMessageFrom: string | null = null;

          if (nextVisibleActivity) {
            nextMessageFrom = nextVisibleActivity.from.role;
          }

          // if menu icons, change to svg
          if (
            activity.attachments &&
            activity.attachments.length > 0 &&
            activity.attachments[0].content &&
            activity.attachments[0].content.actions
          ) {
            activity.attachments[0].content.actions.forEach((v, i) => {
              if (v.iconUrl && v.iconUrl.indexOf('/menuLogo/') >= 0) {
                v.iconUrl = v.iconUrl.replace('.png', '.svg');
              }
            });
          }

          let result: any = null;

          if (prevMessageFrom === messageFrom && messageFrom === nextMessageFrom) {
            // middle message
            result = (
              <div
                style={styleOptions}
                className={classList({
                  'takeover-message': isTakeoverMessage,
                  'from-user': messageFrom === 'user',
                  'from-bot': messageFrom === 'bot'
                })}
              >
                {next(card)(...children)}
              </div>
            );
          } else if (prevMessageFrom !== messageFrom && messageFrom !== nextMessageFrom) {
            // message block with only 1 message
            result = (
              <div
                style={styleOptions}
                className={classList({
                  'takeover-message': isTakeoverMessage,
                  'single-message-in-block': true,
                  'from-user': messageFrom === 'user',
                  'from-bot': messageFrom === 'bot'
                })}
              >
                {next(card)(...children)}
              </div>
            );
          } else if (prevMessageFrom !== messageFrom) {
            // beginning of message block
            result = (
              <div
                style={styleOptions}
                className={classList({
                  'takeover-message': isTakeoverMessage,
                  'first-message-in-block': true,
                  'from-user': messageFrom === 'user',
                  'from-bot': messageFrom === 'bot'
                })}
              >
                {next(card)(...children)}
              </div>
            );
          } else if (messageFrom !== nextMessageFrom) {
            // end of message block
            result = (
              <div
                style={styleOptions}
                className={classList({
                  'takeover-message': isTakeoverMessage,
                  'last-message-in-block': true,
                  'from-user': messageFrom === 'user',
                  'from-bot': messageFrom === 'bot'
                })}
              >
                {next(card)(...children)}
              </div>
            );
          }

          if (nextVisibleActivity) {
            prevMessageFrom = messageFrom;
          } else {
            prevMessageFrom = null;
          }

          return result;
        };

const TakeOverBotAvatar = () => {
  return <img src="/media/takeover.png" alt=""></img>;
};

// let takenOver: boolean = false;

const avatarMiddleware =
  () =>
    (next) =>
      (...args) => {
        const [{ activity }] = args;

        // If message sent by live agent
        if (activity && activity.type === 'message' && activity.from && activity.from.role === 'liveagent') {
          return () => <TakeOverBotAvatar />;
        }

        return next(...args);
      };

const cardActionMiddleware =
  (onBuyPopupOpen, setVINForPurchase) =>
    () =>
      (next) =>
        ({ cardAction, getSignInUrl }) => {
          // if (cardAction.type === 'imBack' && cardAction.value === '1ZVBP8KZ0E5240610') {
          //     onBuyPopupOpen();
          //     let vin = '1ZVBP8KZ0E5240610';
          //     window.parent.postMessage({ sender: 'ChatWindow', event: 'SetBuyVIN', payload: vin }, '*');
          //     window.parent.postMessage({ sender: 'ChatWindow', event: 'SetBuyConversationId', payload: getConversationId() }, '*');
          //     return null
          // }
          // Intercept the 'Buy Now' button
          if (cardAction.type === 'imBack' && cardAction.value.startsWith('I want to buy ')) {
            onBuyPopupOpen(); // Sets { buyPopupOpened: true } in App state
            let vin = cardAction.value.substring('I want to buy '.length);
            setVINForPurchase(vin);
            return null;
          } else {
            return next({ cardAction, getSignInUrl });
          }
        };

export class ChatWindow extends React.Component<ChatWindowProps, ChatWindowState> {
  private menuIconColorResult: any;
  private onReceiveMessage: any;

  constructor(props: any) {
    super(props);

    const { configuration, token } = this.props;

    const rgb = this.hexToRgb(configuration.accentColor);
    const color = new Color(rgb[0], rgb[1], rgb[2]);
    const solver = new Solver(color);
    this.menuIconColorResult = solver.solve();

    this.state = {
      directLineToken: token,
      menu: null,
      chatTyping: false,
      getMessage: '',
      inputFromWelcome: false,
    };

    this.onReceiveMessage = this.onReceiveMessageFromParent.bind(this);
  }

  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }

  componentDidMount() {
    // Adds text-us button if user isn't typing
    var webChatInput = document
      .getElementsByClassName('chat-window-messages')[0]
      .getElementsByTagName('INPUT')[0] as HTMLInputElement;
    var smsButton = document.getElementById('smsButton');

    if (smsButton && webChatInput) {
      webChatInput.addEventListener('focus', function () {
        smsButton.style.display = 'none';
      });
      webChatInput.addEventListener('blur', function () {
        if (webChatInput.value === '') {
          smsButton.style.display = 'block';
        }
      });
    }

    // Remove avatars from takeover messages
    var takeOverMessages = document.getElementsByClassName('takeover-message');
    for (var i = 0; i < takeOverMessages.length; i++) {
      var avatarImages = takeOverMessages[i].getElementsByTagName('img'); //second console output
      for (var j = 0; j < avatarImages.length; j++) {
        avatarImages[j].style.visibility = 'hidden';
      }
    }

    window.addEventListener('message', this.onReceiveMessage);
  }

  componentWillUnmount() {
    // Remove event listeners
    var webChatInput = document
      .getElementsByClassName('chat-window-messages')[0]
      .getElementsByTagName('INPUT')[0] as HTMLInputElement;
    var smsButton = document.getElementById('smsButton');

    if (smsButton && webChatInput) {
      webChatInput.removeEventListener('focus', function () {
        smsButton.style.display = 'none';
      });
      webChatInput.removeEventListener('blur', function () {
        smsButton.style.display = 'block';
      });
    }

    window.removeEventListener('message', this.onReceiveMessage);
  }

  componentDidUpdate() { }

  handleThemeChange() {
    const { onChangeTheme, themeName } = this.props;
    let nextTheme = themeName === 'light' ? 'dark' : 'light';
    onChangeTheme(nextTheme);
  }

  onReceiveMessageFromParent(e: MessageEvent) {
    const { event, payload } = e.data;

    if (event === 'api.message') {
      // check if token already expired?
      if (this.props.token.expiryDate && new Date(this.props.token.expiryDate) < new Date()) {
        console.debug('token expired when use first engage. Fetching new token');
        this.props.fetchDirectlineToken();
      } 

      if (!this.props.chatOpened) {
        this.props.handleChatOpened(true);
      }

      this.handleGetMessage(payload.message);
    } else if (event === 'api.send') {
      // check if token already expired?
      if (this.props.token.expiryDate && new Date(this.props.token.expiryDate) < new Date()) {
        console.debug('token expired when use first engage. Fetching new token');
        this.props.fetchDirectlineToken();
      }
      if (!this.props.chatOpened) {
        this.props.setMetadata(payload);
        this.props.handleChatOpened(true);        
      } else {
        this.props.store.dispatch({
          type: 'WEB_CHAT/SEND_EVENT',
          payload: {
            name: 'webchat/metadata', value: {
              metadata: payload,
            }
          }
        });
      }
    } else if (event === 'GAClientId') {
      //payload
      console.debug('Send GAClientId', payload);
      this.props.store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'webchat/ga_clientid',
          value: {
            clientId: payload
          }
        }
      });
    }
  }

  handleOpenPopover(currentTarget) {
    const closeEsc = (e) => {
      if (e.key === 'Escape') {
        this.setState({ menu: null });
        window.removeEventListener('keydown', closeEsc);
      }
    };

    const closeClick = (e) => {
      let popover = document.getElementById('language-popover') as HTMLDivElement;
      if (this.state.menu && !popover.contains(e.target)) {
        this.setState({ menu: null });
        document.removeEventListener('click', closeClick, false);
      } else if (!this.state.menu) {
        window.removeEventListener('keydown', closeEsc);
        document.removeEventListener('click', closeClick, false);
      }
    };

    if (this.state.menu) {
      this.setState({ menu: null });
      window.removeEventListener('keydown', closeEsc);
      document.removeEventListener('click', closeClick, false);
    } else {
      this.setState({ menu: currentTarget });
      window.addEventListener('keydown', closeEsc);
      document.addEventListener('click', closeClick, false);
    }
  }

  handleSubmitInput(message) {
    // Only gets called when message is submitted from welcome window
    this.setState({ inputFromWelcome: true });

    if (!this.props.chatOpened) {
      this.props.handleChatOpened(true);
    }

    // check if token already expired?
    if (this.props.token.expiryDate && new Date(this.props.token.expiryDate) < new Date()) {
      console.debug('token expired when use first engage. Fetching new token');
      this.props.fetchDirectlineToken();
    }

    this.handleGetMessage(message);
  }

  handleGetMessage(message) {
    this.setState({ getMessage: message });
  }
  
  handleSetInputFromWelcome(inputFromWelcome) {
    this.setState({ inputFromWelcome: inputFromWelcome });
  }

  render() {
    const { configuration, userId, onClose, onBuyPopupOpen, themeName, color } = this.props;

    return (
      <div className="chat-window-container">
        <header className="chat-header-bar">
          <div className="chat-header-toolbar">
            {!this.props.expandMode && (this.props.hasEngaged ||
              this.props.chatOpened) ? (
              <Button
                className={'chat-header-icon edge-start'}
                title={i18next.t('Reset Chat')}
                onClick={() => this.props.handleManualResetWebchatDialogOpen(true)}
                type="icon"
                tooltip={true}
                tooltipTitle={i18next.t('Reset Chat')}
                tooltipPlacement="bottom"
              >
                <ExitChatIcon color={color} />
              </Button>
            ) : (
              <></>
            )}
            {themeName === 'light' ? (
              <Button
                className="chat-header-icon edge-start"
                onClick={() => this.handleThemeChange()}
                type="icon"
                tooltip={true}
                tooltipPlacement="bottom"
                tooltipTitle={i18next.t('Dark')}
              >
                <Brightness4OutlinedIcon color={color} />
              </Button>
            ) : (
              <Button
                className="chat-header-icon edge-start"
                onClick={() => this.handleThemeChange()}
                type="icon"
                tooltip={true}
                tooltipPlacement="bottom"
                tooltipTitle={i18next.t('Light')}
              >
                <Brightness7OutlinedIcon color={color} />
              </Button>
            )}

            {!this.props.expandMode && (this.props.hasEngaged ||
              this.props.chatOpened) ? (
              <Button
                className={(!this.props.smsSet ? 'hide ' : '') + 'button-background-hover chat-header-icon edge-start'}
                onClick={() => this.props.onClickSmsButton()}
                type="icon"
                tooltip={true}
                tooltipPlacement="bottom"
                tooltipTitle={i18next.t('Text us')}
              >
                <SMSIconSvg color={color} />
              </Button>
            ) : (
              <></>
            )}
            <p className="chat-header-space"></p>
            {!this.props.expandMode && (
              <Button className="chat-header-icon edge-end" onClick={onClose} type="icon">
                <CloseIcon color={color} />
              </Button>)
            }
          </div>
        </header>
        <div className="chat-window-messages">
          {this.props.hasEngaged ||
            this.props.chatOpened ? (
            <Suspense
              fallback={
                <WebChatWelcome
                  config={this.props.configuration}
                  onSubmitInputHandler={this.handleSubmitInput.bind(this)}
                  connecting={true}
                  conversationId={this.props.token.conversationId}
                  language={this.props.language}
                  userProfile={this.props.userProfile}
                  activeMessageMatch={this.props.activeMessageMatch}
                  currentTime={this.props.currentTime}
                  postWelcomeHeightMessage={this.props.postWelcomeHeightMessage}
                />
              }
            >
              <WebChatReal
                language={this.props.language}
                configuration={configuration}
                userId={userId}
                themeName={themeName}
                token={this.props.token}
                shouldHideChat={this.props.shouldHideChat}
                userProfile={this.props.userProfile}
                activityMiddleware={activityMiddleware}
                avatarMiddleware={avatarMiddleware}
                cardActionMiddleware={cardActionMiddleware(onBuyPopupOpen, this.props.setVINForPurchase)}
                directLineToken={this.state.directLineToken}
                setDirectLineToken={(token) => this.setState({ directLineToken: token })}
                onTokenRefreshed={this.props.onTokenRefreshed}
                dispatchCalled={this.props.dispatchCalled}
                onDispatchActivity={this.props.onDispatchActivity}
                onConnectionStatusFailed={this.props.onConnectionStatusFailed}
                onAuthenticated={this.props.onAuthenticated}
                postAuthenticatedMessage={this.props.postAuthenticatedMessage}
                postWelcomeHeightMessage={this.props.postWelcomeHeightMessage}
                resetWebChat={this.props.resetWebChat}
                webchatConnecting={this.props.webchatConnecting}
                webChatLoaded={this.props.webChatLoaded}
                handleWebChatLoaded={this.props.handleWebChatLoaded}
                getHelp={this.props.getHelp}
                onHelp={this.props.onHelp}
                getButton={this.props.getButton}
                onButton={this.props.onButton}
                getMessage={this.state.getMessage}
                onMessage={this.handleGetMessage.bind(this)}
                onSubmitInputHandler={this.handleSubmitInput.bind(this)}
                waitingForBotReply={this.props.waitingForBotReply}
                userMessageSent={this.props.userMessageSent}
                handleUserMessageSent={this.props.handleUserMessageSent}
                activeMessageMatch={this.props.activeMessageMatch}
                manualResetWebchatDialogOpen={this.props.manualResetWebchatDialogOpen}
                handleManualResetWebchatDialogOpen={this.props.handleManualResetWebchatDialogOpen}
                inputFromWelcome={this.state.inputFromWelcome}
                handleSetInputFromWelcome={this.handleSetInputFromWelcome.bind(this)}
                currentTime={this.props.currentTime}
                store={this.props.store}
                setStore={this.props.setStore}
              />
            </Suspense>
          ) : (
            <WebChatWelcome
              config={this.props.configuration}
              onSubmitInputHandler={this.handleSubmitInput.bind(this)}
              conversationId={this.props.token.conversationId}
              language={this.props.language}
              userProfile={this.props.userProfile}
              activeMessageMatch={this.props.activeMessageMatch}
              currentTime={this.props.currentTime}
              postWelcomeHeightMessage={this.props.postWelcomeHeightMessage}
            />
          )}
        </div>
        {this.props.showSmsButton && this.props.smsSet && (
          <Button
            className={'contained-primary typography-small'}
            id="smsButton"
            style={{
              position: 'absolute',
              bottom: 3.665,
              right: 3.665
            }}
            onClick={() => this.props.onClickSmsButton()}
          >
            {i18next.t('or Text Us')}
          </Button>
        )}
      </div>
    );
  }
}