import * as React from 'react';
import Button from '../Button/Button';
import CancelRoundedIcon from '../IconButtons/CancelRoundedIcon';

import ChatWelcomeProps from './ChatWelcomeProps';
import './ChatWelcome.css';

export class ChatWelcome extends React.Component<ChatWelcomeProps, any> {
    constructor(props: any) {
        super(props);

        this.closePopup = this.closePopup.bind(this);
    }

    componentWillUnmount() {
    }

    closePopup(event) {
        event.stopPropagation();
        this.props.onClose();
    }

    render() {
        const { mobile, configuration, onClick } = this.props;
		return (
            <div className="chat-welcome">
                {
					mobile ?
						<div className="chat-welcome-mobile">{this.props.message}</div>
                        :
                        <div className="chat-welcome-desktop" onClick={onClick}>
                            <div className="chat-welcome-message">
                                <img className="chat-welcome-avatar" src={`${configuration.avatar.trim()}`} alt="AIBot" />
								<div className="chat-welcome-bubble">
									<p className="chat-welcome-secondary-welcome">{this.props.message}</p>
                                </div>
                                <Button className="chat-welcome-close-button" onClick={this.closePopup} type="icon">
                                    <CancelRoundedIcon />
                                </Button>
                            </div>
                        </div>
                }
            </div>
		);
	}
}
