import React, { useEffect, useState, Suspense, useRef } from 'react';
import WebchatSendIcon from '../IconButtons/WebchatSendIcon';
import './WebChatWelcome.css';
import i18next from 'i18next';
import { fetchWebChatWelcomeMessage } from '../../api/fetchWebChatWelcomeMessage';

const Spinner = React.lazy(() => import('../Spinner/Spinner'));

const WebChatWelcome = ({
  config,
  onSubmitInputHandler,
  connecting = false,
  conversationId,
  language,
  userProfile,
  activeMessageMatch,
  currentTime,
  postWelcomeHeightMessage
}) => {
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    const welcomeMessageContainer = ref.current;

    if (
      welcomeMessageContainer !== null &&
      welcomeMessageContainer !== undefined &&
      welcomeMessageContainer.scrollHeight !== undefined
    ) {
      postWelcomeHeightMessage(welcomeMessageContainer.scrollHeight);
    }
  });

  useEffect(() => {
    async function getWelcomeMessage() {
      if (activeMessageMatch != null) {
        setWelcomeMessage(activeMessageMatch.message);
      } else {
        setWelcomeMessage(
          await fetchWebChatWelcomeMessage(
            config.dealerId,
            conversationId,
            language.display,
            config,
            userProfile,
            currentTime
          )
        );
      }
    }
    if (!connecting) {
      getWelcomeMessage();
    }
  }, [config, conversationId, language, connecting, userProfile, activeMessageMatch, currentTime]);

  function onSendButtonClicked() {
    let inputText = document.getElementById('webchat-welcome-send-input') as HTMLInputElement;

    if (inputText && inputText.value.trim().length) {
      onSubmitInputHandler(inputText.value.trim());
    }
  }

  function onInputEnterPressed(e) {
    let inputText = document.getElementById('webchat-welcome-send-input') as HTMLInputElement;

    if (e.key === 'Enter') {
      e.preventDefault();
      if (inputText && document.activeElement === inputText && inputText.value.trim().length) {
        onSubmitInputHandler(inputText.value.trim());
      }
    }
  }

  return (
    <div className="webchat-welcome">
      {!connecting ? (
        <div className="webchat-welcome-messages">
          <div className="webchat-welcome-initial">
            <div className="webchat-welcome-initial-avatar">
              <img src={`${config.avatar.trim()}`} alt="BOT" />
            </div>
            <div className="webchat-welcome-initial-message-container">
              <div className="webchat-welcome-initial-message-box">
                <div className="webchat-welcome-initial-message-pad"></div>
                <div className="webchat-welcome-initial-message" ref={ref}>
                  <p>{welcomeMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="webchat-welcome-connecting-container">
          <div className="webchat-welcome-connecting">
            <Suspense fallback={<div></div>}>
              <Spinner />
            </Suspense>
            <div className="webchat-welcome-connecting-text">{i18next.t('Connecting')}</div>
          </div>
        </div>
      )}
      <div className="webchat-welcome-send">
        <div className="webchat-welcome-send-box">
          <input
            id="webchat-welcome-send-input"
            type="text"
            className="webchat-welcome-send-input"
            autoComplete="off"
            placeholder={i18next.t('Type your message')}
            onKeyDown={onInputEnterPressed}
            disabled={connecting}
          />
        </div>
        <button className="webchat-welcome-send-button" type="button" onClick={onSendButtonClicked}>
          <WebchatSendIcon />
        </button>
      </div>
    </div>
  );
};

export default WebChatWelcome;
